import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common';

@Injectable()
export class DateInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (request.url.startsWith('api/')) {
                        this.convertDates(event.body);
                    }
                }
            });
    }

    private convertDates(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convertDates(item);
            }
        }

        for (const key of Object.keys(object)) {
            const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convertDates(item);
                }
            }

            if (value instanceof Object) {
                this.convertDates(value);
            }

            if (typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid()) {
                var milliseconds = Date.parse(value);
                if (!isNaN(milliseconds) && value.indexOf('T') > 0) {
                    object[key] = moment.tz(value, "America/New_York").format('l LTS');
                    object[key] = new Date(object[key]);
                }
            } else if (typeof value === "object") {
                // Recurse into object
                this.convertDates(value);
            }
        }
    }
}
