import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OktaAuthGuard, OktaCallbackComponent, } from '@okta/okta-angular';
import { DashboardComponent } from '../app/pages/dashboard/dashboard.component';
import { UserProfileComponent } from '../app/pages/userProfile/userProfile.component';
import { LogDetailComponent } from '../app/pages/dashboard/logDetail.component';
import { ImportProcessComponent } from '../app/pages/importProcess/importProcess.component';
import { AccessDeniedComponent } from '../app/pages/common/accessDenied.component';



export const AppRoutes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [OktaAuthGuard],},
    { path: 'dashboard', component: DashboardComponent, canActivate: [OktaAuthGuard], },
    { path: 'userProfile', component: UserProfileComponent, canActivate: [OktaAuthGuard], },
    { path: 'log', component: LogDetailComponent, canActivate: [OktaAuthGuard], },
    { path: 'importProcess', component: ImportProcessComponent, canActivate: [OktaAuthGuard], },
    { path: 'accessdenied', component: AccessDeniedComponent, canActivate: [OktaAuthGuard], },
    { path: 'implicit/callback', component: OktaCallbackComponent },
    //{ path: '', component: DashboardComponent, pathMatch: 'full' },

];
const config: ExtraOptions = {
    useHash: true,
};
@NgModule({
    imports: [RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

