import { Component, Inject, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { DataTape, DataLog } from '../dashboard/dashboard.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ngx-printReport',
    templateUrl: './printReport.component.html'
})
export class PrintReportComponent {
    reportObj: DataTape;
    isVisible: boolean = true;
    constructor(public dialogRef: MatDialogRef<PrintReportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        if (data !== null) {
            this.reportObj = data;
            this.reportObj.locData = this.sanitizer.sanitize(SecurityContext.HTML, this.reportObj.location);
        }
        else {

        }
    }

    formatDate(date) {
        if (date != null) {
            var d = new Date(date);
            date = [
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2),
                d.getFullYear()
            ].join('/');
            return date;
        }
    }

    closeModal() {
        this.dialogRef.close();
    }
    savePDF(pdfObj) {
        //this.isVisible = true;
        pdfObj.saveAs('Report.pdf')
    }

    printReport() {
        //this.isVisible = false;
        const printContent = document.getElementById('print').innerHTML;
        var popupWin = window.open('', '_blank');

        popupWin.document.open();
        popupWin.document.write("<html><head></head><body onload='window.print()'>" + printContent + "</body></html>");
        popupWin.document.close();
    }
}