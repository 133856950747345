import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MenuItems } from '../app/shared/menu-items/menu-items';
import { OktaAuthService } from '@okta/okta-angular';
import { AppHeaderComponent } from '../app/layouts/header/header.component';
import { AppSidebarComponent } from '../app/layouts/sidebar/sidebar.component';
import { AuthenticationService } from '../app/services/authentication.service';

/** @title Responsive sidenav */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: []
})
export class AppComponent implements OnDestroy, AfterViewInit {
    mobileQuery: MediaQueryList;
    version = "";
    server = "";
    isProd = false;
    public isAuthenticated: boolean;

    seconds = 1500;
    timer: any = 0;
    isCancel = false;

    private _mobileQueryListener: () => void;

    constructor(public oktaAuth: OktaAuthService,changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems, public authenticationService: AuthenticationService) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.oktaAuth.$authenticationState.subscribe(
            (isAuthenticated: boolean) => (this.isAuthenticated = isAuthenticated)
        );
        authenticationService.getCurrentVersion().subscribe((data) => {
            this.version = data[0];
            switch (window.location.host) {

                case "localhost:4200":
                    this.server = 'Local Dev. Machine';
                    break;

                case "dnwkwswebs01":
                case "dev-sporttrack.cbs.com":
                case "dev.sportstrack.viacomcbs.com":
                case "csn-stk-dev-app.azurewebsites.net":
                    this.server = 'Development System';
                    break;

                case "awcsnwssptrk01":
                case "test-sporttrack.cbs.com":
                case "qas.sportstrack.viacomcbs.com":
                case "csn-stk-qa-app.azurewebsites.net":
                    this.server = 'QA System';
                    break;

                case "stg.sportstrack.viacomcbs.com":
                case "csn-stk-uat-app.azurewebsites.net":
                    this.server = 'UAT System';
                    break;

                case "pwcsnwssptrk01":
                case "sporttrack.cbs.com":
                case "sporttrackweb.cbs.com":
                case "sportstrack.viacomcbs.com":
                case "csn-stk-prod-app.azurewebsites.net":
                    this.server = 'Production System';
                    this.isProd = true;
                    break;

                default:
                    this.server = 'Unknown System';
            }
        });
    }


    async ngOnInit() {
        this.isAuthenticated = await this.oktaAuth.isAuthenticated();
        if (this.isAuthenticated) {
            const userClaims = await this.oktaAuth.getUser();
           var userName = userClaims.preferred_username.substr(0, userClaims.preferred_username.indexOf("@"));
            this.authenticationService.getLoggedInUser(userName);
        }
        //this.authenticationService.getCurrentUser();
        //this.init();
    }
    onMouseMove(e) {
        this.resetCounter();
    }

    init() {
        if (this.seconds <= 0 && !this.isCancel) {
            this.authenticationService.keepAlive().subscribe((data) => {
                if (data) {
                    this.resetCounter();
                } else {
                    if (this.timer)
                        clearTimeout(this.timer);
                    if (window.confirm('The session has expired, and application will redirect to Landing page.')) {
                        window.location.reload();
                    }
                    else {
                        this.isCancel = true;
                    }
                }
            });
        }
        this.seconds--;

        if (this.isCancel) {
            if (this.timer)
                clearTimeout(this.timer);
        }
        else {
            this.timer = setTimeout(() => { this.init(); }, 1000);
        }
    }

    resetCounter() {
        this.seconds = 1500;
    }
    //async ngOnInit() {
    //    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    //    this.oktaAuth.$authenticationState.subscribe(
    //        (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    //    );
    //}
    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    ngAfterViewInit() {

    }

}
