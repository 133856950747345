import { Component, Inject, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { DataTape, DataLog } from '../dashboard/dashboard.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ngx-printSearchReport',
    templateUrl: './printSearchReport.component.html'
})
export class PrintSearchReportComponent {
    reportObj: any = {};
    reportSearchObj: any = {};

    constructor(public dialogRef: MatDialogRef<PrintSearchReportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        if (data !== null) {
            this.reportObj = data.searchData;
            this.reportSearchObj = data.searchParameter;
            this.reportSearchObj.locData = this.sanitizer.sanitize(SecurityContext.HTML, this.reportSearchObj.location);
        }
        else {

        }
    }

    closeModal() {
        this.dialogRef.close();
    }

    printReport() {
        const printContent = document.getElementById('print').innerHTML;
        var popupWin = window.open('', '_blank');

        popupWin.document.open();
        popupWin.document.write("<html><head></head><body onload='window.print()'>" + printContent + "</body></html>");
        popupWin.document.close();
    }
}