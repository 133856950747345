import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';  
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { OKTA_CONFIG, OktaAuthGuard, OktaAuthModule, OktaCallbackComponent, } from '@okta/okta-angular';

import { AppHeaderComponent } from './layouts/header/header.component';
import { AppSidebarComponent } from './layouts/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmComponent } from '../app/pages/common/confirm.component';

import { CommonModule } from '@angular/common';
import { MaterialModule } from '../app/material-module';
import { MatTableModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TextMaskModule } from 'angular2-text-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from '../app/app.routing.module';
import { ErrorInterceptor } from './services/auth-interceptor.service';
import { DashboardComponent } from '../app/pages/dashboard/dashboard.component';
import { UserProfileComponent } from '../app/pages/userProfile/userProfile.component';
import { LogDetailComponent } from '../app/pages/dashboard/logDetail.component';
import { ImportProcessComponent } from '../app/pages/importProcess/importProcess.component';
import { AccessDeniedComponent } from '../app/pages/common/accessDenied.component';
import { AddUserProfileComponent } from '../app/pages/userProfile/addUserProfile.component';
import { AddLogComponent } from '../app/pages/dashboard/addLog.component';
import { PrintReportComponent } from '../app/pages/dashboard/printReport.component';
import { PrintSearchReportComponent } from '../app/pages/dashboard/printSearchReport.component';
import { environment } from '../environments/environment';
//import { CustomDateAdapter } from './custom-date-adaptor';

import { DateInterceptor } from './date.interceptor';
@NgModule({
    declarations: [
        AppComponent,
        AppHeaderComponent,
        SpinnerComponent,
        AppSidebarComponent,
        ConfirmComponent,
        DashboardComponent,
        UserProfileComponent,
        AddUserProfileComponent,
        LogDetailComponent,
        AddLogComponent,
        ImportProcessComponent,
        PrintReportComponent,
        AccessDeniedComponent,
        PrintSearchReportComponent
    ],
    entryComponents: [ConfirmComponent, DashboardComponent, AddUserProfileComponent, AddLogComponent, PrintReportComponent, AccessDeniedComponent, PrintSearchReportComponent
    ],

    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        NgbModule,
        AppRoutingModule,
        BlockUIModule.forRoot(),
        ToastrModule.forRoot(),
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        CommonModule,
        MatTableModule,
        PDFExportModule,
        TextMaskModule,
        InfiniteScrollModule,
        OktaAuthModule,
    ],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: DatePipe, useValue: {} },
        //{ provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: OKTA_CONFIG,
            useFactory: () => ({
                issuer: environment.OktaIssuer,
                redirectUri: environment.OktaRedirectUri,
                clientId: environment.OktaClientId,
                scopes: ['openid', 'profile', 'email'],
                pkce: true,
            })
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
