import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


export function getBaseUrl() {
    //return "http://localhost:5001/";
    document.getElementsByTagName('base')[0].href;
}


const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];


if (environment.production) {
  enableProdMode();
}

(async () => {
    const response = await fetch('../../../assets/config/OktaConfig.json');
    const json = await response.json();
    Object.entries(json).forEach(([key, value]) => {
        environment[key] = value;
    });
    
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
})();